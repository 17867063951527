import React from "react";
import {
  Carousel,
  ChatItem,
  Message,
  SourceMessage as SourceMessageType,
} from "../../types/types";
import {
  CarouselMessage,
  SourceMessage,
  SystemMessage,
  ThoughtMessage,
  UserMessage,
} from "./Messages/index";

interface ChatItemRendererProps {
  chatItem: ChatItem;
}

const ChatItemRenderer: React.FC<ChatItemRendererProps> = ({ chatItem }) => {
  switch (chatItem.type) {
    case "user":
      return <UserMessage message={chatItem as Message} />;
    case "source":
      return <SourceMessage message={chatItem as SourceMessageType} />;
    case "system":
    case "answer":
    case "rag_synthesis":
    case "final_synthesis":
      return <SystemMessage message={chatItem as Message} />;
    case "def":
    case "mq":
    case "thought":
    case "tool":
      return <ThoughtMessage message={chatItem as Message} />;
    case "carousel":
      return <CarouselMessage carousel={chatItem as Carousel} />;
  }
};

export default ChatItemRenderer;
