import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { MultiSelect } from "primereact/multiselect";
import { ConfigProvider, Slider } from "antd";
import Accordion from "react-bootstrap/Accordion";
import Collapse from "react-bootstrap/Collapse";
import styles from "./Filters.module.scss"; // Import the styles
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

let mainFilterConfig = require("../../../../../../data/mainFilters.json");
let dealFilterConfig = require("../../../../../../data/dealFilters.json");
let docFilterConfig = require("../../../../../../data/docFilters.json");

const FilterLabel = ({ filter }) => (
  <>
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`tooltip-${filter.id}`}>
          {filter.tooltip}
        </Tooltip>
      }
      delay={{ show: 150, hide: 200 }}
    >
      <span className={styles.infoIconWrapper}>
        <span className={styles.infoIcon}>
          <i className="bi bi-info-circle"></i>
        </span>
      </span>
    </OverlayTrigger>
    <Form.Label style={{ fontSize: "0.95em" }}>
      {filter.label}
    </Form.Label>
  </>
);

const MultiSelectFilter = ({
  filter,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  return (
    <>
      <FilterLabel filter={filter} />
      <MultiSelect
        options={filters ? filters[filter.id] : []}
        value={selectedFilters && selectedFilters[filter.id]}
        onChange={(selected) => {
          setSelectedFilters({
            ...selectedFilters,
            [filter.id]: selected.value,
          });
        }}
        filter
        virtualScrollerOptions={{ itemSize: 40 }}
        placeholder=""
        scrollHeight="400px"
        display="chip"
        maxSelectedLabels={2}
        showClear
        style={{ width: "100%" }}
      />
    </>
  );
};

function Filters({ criterias, filters, setFilters }) {

  const SliderRange = ({
    filter,
    filters,
    selectedFilters,
    setSelectedFilters,
  }) => {
    const min =
      (filters && filters[filter.id] && filters[filter.id]["min"]) || 0;
    const max =
      (filters && filters[filter.id] && filters[filter.id]["max"]) || 100;
    const minMaxToList = ({ min, max }) => [min, max];
    const listToMinMax = (l) => {
      return { min: l[0], max: l[1] };
    };

    const formatTooltip = (value) => {
      if (filter.id === "shares") {
        return `${Math.round(value)}%`;
      }
      if (filter.id === "fundingSize" || filter.id === "companySize") {
        return `${value} M€`;
      }
      return value;
    };

    return (
      <>
        <FilterLabel filter={filter} />
        <Slider
          range
          defaultValue={
            (selectedFilters &&
              selectedFilters[filter.id] &&
              minMaxToList(selectedFilters[filter.id])) ||
            (filters && filters[filter.id] && minMaxToList(filters[filter.id]))
          }
          onChangeComplete={(v) =>
            setSelectedFilters({
              ...selectedFilters,
              [filter.id]: listToMinMax(v),
            })
          }
          min={min}
          max={max}
          tooltip={{ formatter: formatTooltip }}
        />
      </>
    );
  };
  // Render each filter element defined in the JSON, depending on type
  const renderFilter = (filter) => {
    switch (filter.type) {
      case "multiselect":
        return (
          <MultiSelectFilter
            filter={filter}
            filters={criterias}
            selectedFilters={filters}
            setSelectedFilters={setFilters}
          />
        );
      case "range":
        return (
          <SliderRange
            filter={filter}
            filters={criterias}
            selectedFilters={filters}
            setSelectedFilters={setFilters}
          />
        );
      case "separator":
        return (
          <>
            <div dangerouslySetInnerHTML={filter.label}></div>
            <hr />
          </>
        );
      default:
        break;
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBorderHover: "#00AFCD",
        },
        components: {
          Slider: {
            dotActiveBorderColor: "#00AFCD",
            handleActiveColor: "#00AFCD",
            handleColor: "#031A3B",
            trackBg: "#031A3B",
            trackHoverBg: "#00AFCD",
            handleSizeHover: 10,
            handleLineWidthHover: 3,
          },
        },
      }}
    >
      <Form className="px-2">
        {mainFilterConfig.map((filter, idx) => (
          <Form.Group
            key={idx}
            className={styles["multi-select-title"]}
            controlId={filter.id}
          >
            {renderFilter(filter)}
          </Form.Group>
        ))}
        <hr />
        <p style={{ color: "darkgrey", textAlign: "center" }}>
          <b>Additional deal filters</b>
        </p>
        <div id="filters">
          {dealFilterConfig.map((filter, idx) => (
            <Form.Group
              key={idx}
              className={styles["multi-select-title"]}
              controlId={filter.id}
            >
              {renderFilter(filter)}
            </Form.Group>
          ))}
        </div>
        <hr />
        <p style={{ color: "darkgrey", textAlign: "center" }}>
          <b>Additional document filters</b>
        </p>
        <div id="doc-filters">
          {docFilterConfig.map((filter, idx) => (
            <Form.Group
              key={idx}
              className={styles["multi-select-title"]}
              controlId={filter.id}
            >
              {renderFilter(filter)}
            </Form.Group>
          ))}
        </div>
      </Form>
    </ConfigProvider>
  );
}

export default Filters;
