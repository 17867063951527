import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { MultiSelect } from "primereact/multiselect";
import { ConfigProvider, Slider } from "antd";
import Accordion from "react-bootstrap/Accordion";
import Collapse from "react-bootstrap/Collapse";
import Image from "react-bootstrap/Image"; // Import the Image component
import styles from "./Filters.module.scss"; // Import the styles
import logo from "../../../../../../assets/images/oreima.png";

let dealFilterConfig = require("../../../../../../data/dealFiltersOreima.json");
let docFilterConfig = require("../../../../../../data/docFiltersOreima.json");

const MultiSelectFilter = ({
  filter,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  return (
    <>
      <Form.Label>{filter.label}</Form.Label>
      <MultiSelect
        options={filters ? filters[filter.id] : []}
        value={selectedFilters && selectedFilters[filter.id]}
        onChange={(selected) => {
          setSelectedFilters({
            ...selectedFilters,
            [filter.id]: selected.value,
          });
        }}
        filter
        virtualScrollerOptions={{ itemSize: 40 }}
        placeholder=""
        scrollHeight="400px"
        display="chip"
        maxSelectedLabels={2}
        showClear
        style={{ width: "100%" }}
        panelStyle={{ width: '400px' }} // Adjust the width as needed
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={logo}
          height="60px"
          className={`${styles.logo} d-inline-block align-top`} // Add custom class here
          alt="oreima"
        />
      </div>
    </>
  );
};

function Filters({ criterias, filters, setFilters }) {
  const SliderRange = ({
    filter,
    filters,
    selectedFilters,
    setSelectedFilters,
  }) => {
    const min =
      (filters && filters[filter.id] && filters[filter.id]["min"]) || 0;
    const max =
      (filters && filters[filter.id] && filters[filter.id]["max"]) || 100;
    const minMaxToList = ({ min, max }) => [min, max];
    const listToMinMax = (l) => {
      return { min: l[0], max: l[1] };
    };
    return (
      <>
        <Form.Label>{filter.label}</Form.Label>
        <Slider
          range
          defaultValue={
            (selectedFilters &&
              selectedFilters[filter.id] &&
              minMaxToList(selectedFilters[filter.id])) ||
            (filters && filters[filter.id] && minMaxToList(filters[filter.id]))
          }
          onChangeComplete={(v) =>
            setSelectedFilters({
              ...selectedFilters,
              [filter.id]: listToMinMax(v),
            })
          }
          min={min}
          max={max}
        />
      </>
    );
  };
  // Render each filter element defined in the JSON, depending on type
  const renderFilter = (filter) => {
    switch (filter.type) {
      case "multiselect":
        return (
          <MultiSelectFilter
            filter={filter}
            filters={criterias}
            selectedFilters={filters}
            setSelectedFilters={setFilters}
          />
        );
      case "range":
        return (
          <SliderRange
            filter={filter}
            filters={criterias}
            selectedFilters={filters}
            setSelectedFilters={setFilters}
          />
        );
      case "separator":
        return (
          <>
            <div dangerouslySetInnerHTML={filter.label}></div>
            <hr />
          </>
        );
      default:
        break;
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBorderHover: "#00AFCD",
        },
        components: {
          Slider: {
            dotActiveBorderColor: "#00AFCD",
            handleActiveColor: "#00AFCD",
            handleColor: "#031A3B",
            trackBg: "#031A3B",
            trackHoverBg: "#00AFCD",
            handleSizeHover: 10,
            handleLineWidthHover: 3,
          },
        },
      }}
    >
      <Form className="px-2">
        {docFilterConfig.map((filter, idx) => (
          <Form.Group
            key={idx}
            className={styles["multi-select-title"]}
            controlId={filter.id}
          >
            {renderFilter(filter)}
          </Form.Group>
        ))}
        <hr />
        <div id="filters">
          {dealFilterConfig.map((filter, idx) => (
            <Form.Group
              key={idx}
              className={styles["multi-select-title"]}
              controlId={filter.id}
            >
              {renderFilter(filter)}
            </Form.Group>
          ))}
        </div>
      </Form>
    </ConfigProvider>
  );
}

export default Filters;
