import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css"; // Import KaTeX CSS
import logo from "../../../assets/images/circles.png";
import useSourceClickHandler from "../../../hooks/useSourceClickHandler";
import { isString } from "../../../types/typeGuards";
import { Message } from "../../../types/types";
import Feedback from "./components/Feedback";
import MarkdownTable from "./components/MarkdownTable";
import styles from "./SystemMessage.module.scss";
import "./SystemMessage.scss";

const Georges: React.FC = () => (
  <img src={logo} height="40px" style={{ marginTop: "10px" }} alt="InfraVia" />
);

interface SystemMessageProps {
  message: Message;
}

const SystemMessage: React.FC<SystemMessageProps> = ({ message }) => {
  const containerRef = useSourceClickHandler();

  return (
    <div className={`d-flex flex-column flex-fill ${styles.message}`}>
      <div className={`d-flex flex-row`}>
        <div className={`${styles.type}`}>
          <Georges />
        </div>
        <div className={`${styles.result} d-flex flex-column`}>
          {message && isString(message.content) && (
            <div ref={containerRef} className={styles.markdownContainer}>
              <ReactMarkdown
                className={styles.markdown}
                // remarkPlugins={[remarkGfm, remarkMath]}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                components={{ table: MarkdownTable }}
              >
                {message.content}
              </ReactMarkdown>{" "}
              {/* Corrected closing tag */}
            </div>
          )}
        </div>
      </div>
      <Feedback message={message} />
    </div>
  );
};

export default SystemMessage;
