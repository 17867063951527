import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface ErrorContextType {
  errors: string[];
  addError: (message: string) => void;
  removeError: (index: number) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [errors, setErrors] = useState<string[]>([]);

  const addError = useCallback(
    (message: string) => {
      setErrors((prevErrors) => [...prevErrors, message]);
    },
    [setErrors]
  );

  const removeError = useCallback(
    (index: number) => {
      setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
    },
    [setErrors]
  );

  const value = React.useMemo(
    () => ({ errors, addError, removeError }),
    [errors, addError, removeError]
  );

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};
