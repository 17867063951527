import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface LoaderProps {
  text: string;
}
const Loader: React.FC<LoaderProps> = ({ text }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: "100%" }}
    >
      <Spinner
        animation="border"
        className="mx-auto my-4 text-body-secondary"
      />
      {/* <strong role="status" className="text-body-secondary"> */}
      {text}
      {/* </strong> */}
    </div>
  );
};
export default Loader;
