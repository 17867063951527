import { MultiSelect } from "primereact/multiselect";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import styles from "./Filters.module.scss";

interface MarketFilters {
  projectName: string[];
  year: string[];
  type: string[];
}

interface FiltersProps {
  criterias: MarketFilters;
  filters: MarketFilters;
  setFilters: (filters: MarketFilters) => void;
}

const Filters: React.FC<FiltersProps> = ({
  criterias,
  filters,
  setFilters,
}) => {
  // Function to get all project names
  const getActiveProjectName = () => {
    return criterias.projectName ? criterias.projectName.sort() : []; // Default to empty array if undefined
  };

  // Function to get all years
  const getActiveYear = () => {
    return criterias.year ? criterias.year.sort() : []; // Default to empty array if undefined
  };

  // Function to get all types
  const getActiveType = () => {
    return criterias.type ? criterias.type.sort() : []; // Default to empty array if undefined
  };

  useEffect(() => {
    // You can add any necessary logic here if needed
  }, [filters]);

  return (
    <div className="d-flex flex-column width-full p-2">
      <Form>
        {/* Project Name Filter */}
        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-project-name">
                Choose the name/s of your projects
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Project Name</Form.Label>
          <MultiSelect
            disabled={
              criterias?.projectName && Array.isArray(criterias.projectName)
                ? false
                : true
            }
            options={getActiveProjectName()} // Get all projects
            value={filters.projectName}
            onChange={(selected) =>
              setFilters({ ...filters, projectName: selected.value })
            }
            placeholder=""
            filter
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Year Filter */}
        {/* <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-year">
                Choose the year/s of the project
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Project Year</Form.Label>
          <MultiSelect
            disabled={
              criterias?.year && Array.isArray(criterias.year) ? false : true
            }
            options={getActiveYear()} // Get all years
            value={filters.year}
            onChange={(selected) =>
              setFilters({ ...filters, year: selected.value })
            }
            placeholder=""
            filter
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group> */}

        {/* Type Filter */}
        {/* <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-project-type">
                Choose the type/s of document that you want to search.
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Document Type</Form.Label>
          <MultiSelect
            disabled={
              criterias?.type && Array.isArray(criterias.type) ? false : true
            }
            options={getActiveType()} // Get all types
            value={filters.type}
            onChange={(selected) =>
              setFilters({ ...filters, type: selected.value })
            }
            placeholder=""
            filter
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group> */}
      </Form>
    </div>
  );
};

export default Filters;
