import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"; // Import OverlayTrigger and Tooltip
import { useChatContext } from "../../../contexts/ChatContext";

interface ChatInputProps {
  filters: object;
  selectedText: string;
  onSend: () => void; // Ensure this is defined
}
const ChatInput: React.FC<ChatInputProps> = ({
  filters,
  selectedText,
  onSend,
}) => {
  const [query, setQuery] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string>("UNDEFINED");
  const { sendQuery, abortQuery, isLoadingQuery } = useChatContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (selectedText) {
      setQuery(selectedText);
    }
  }, [selectedText]);

  const handleQuery = (questionType: string) => {
    if (query.trim()) {
      const payload = { query, filters, question_type: questionType };
      sendQuery(payload);
      setQuery("");
      onSend();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(event.target.value);
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const options = [
    {
      value: "UNDEFINED",
      icon: "bi bi-robot",
      label: "AI decides your mode",
      tooltip:
        "Let Georges decide if you ask a question (Database Search) or if you want to chat (ChatGPT)",
    },
    {
      value: "SEARCH",
      icon: "bi bi-database",
      label: "Database Search",
      tooltip:
        "Georges will always search for documents on InfraVia's internal database",
    },
    {
      value: "CHAT",
      icon: "bi bi-chat",
      label: "ChatGPT",
      tooltip:
        "Engage in a chat with Georges, he will not search for information on InfraVia's internal database",
    },
  ];

  const handleModeSelect = (mode: string) => {
    setSelectedMode(mode);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return;
      }
      event.preventDefault();
      if (query.trim()) {
        handleQuery(selectedMode);
      }
    }
  };

  const handleStopQuery = () => {
    abortQuery();
  };

  return (
    <div>
      <Form className="width-full flex-fill">
        <InputGroup className="my-3 justify-content-center position-relative">
          <InputGroup.Text>
            <i className="bi bi-search"></i>
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            ref={inputRef}
            disabled={isLoadingQuery}
            id="inlineFormInputGroup"
            placeholder="Ask anything ..."
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="pe-5"
            style={{
              resize: "none",
              overflow: "hidden",
              maxHeight: "200px",
              borderRadius: "5px",
            }}
            rows={1}
          />
          {isLoadingQuery ? (
            <Button variant="outline-danger" onClick={handleStopQuery}>
              <i className="bi bi-stop"></i>
            </Button>
          ) : (
            <Button
              variant="outline-primary"
              onClick={() => handleQuery(selectedMode)}
            >
              <i
                className={
                  options.find((option) => option.value === selectedMode)?.icon
                }
              ></i>
            </Button>
          )}
          <Dropdown>
            <Dropdown.Toggle variant="primary">Select Mode</Dropdown.Toggle>
            <Dropdown.Menu>
              {options.map((option) => (
                <OverlayTrigger
                  key={option.value}
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${option.value}`}>
                      {option.tooltip}
                    </Tooltip>
                  }
                >
                  <Dropdown.Item
                    onClick={() => handleModeSelect(option.value)}
                    active={selectedMode === option.value}
                  >
                    <i className={option.icon}></i>
                    <span className="ms-2">{option.label}</span>
                  </Dropdown.Item>
                </OverlayTrigger>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </Form>
    </div>
  );
};

export default ChatInput;
