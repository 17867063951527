import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap"; // Import Button from react-bootstrap
import { useChatContext } from "../../../contexts/ChatContext"; // Updated path
import useAutoScroll from "../../../hooks/useAutoScroll"; // Updated path
import ChatInput from "./ChatInputlegaloreima"; // Updated path
import ChatItemsDisplay from "../../../components/Chat/ChatItemsDisplay"; // Corrected path
import styles from "./Chatlegaloreima.module.scss"; // Updated path
import content from "../../../data/textboxContent.json"; // Updated path

interface ChatProps {
  filters: object;
  page: "market" | "legal" | "mydeal";
}

const Chat: React.FC<ChatProps> = ({ filters, page }) => {
  console.log("Current page:", page); // Add this line at the beginning of the component

  const { chatItems } = useChatContext();
  const { containerRef, bottomRef, isUserAtBottom, scrollToBottom } =
    useAutoScroll();
  const [showSquares, setShowSquares] = useState(true);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    if (isUserAtBottom) scrollToBottom();
    setShowSquares(chatItems.length === 0);
  }, [chatItems, scrollToBottom, isUserAtBottom]);

  const squareContent = React.useMemo(() => {
    console.log("Calculating squareContent for page:", page);
    return content[page]; // Access the content based on the page key
  }, [page]);

  const handleSquareClick = (text: string) => {
    console.log(`Square with text "${text}" clicked`);
    setSelectedText(text);
  };

  const handleSend = () => {
    setShowSquares(false);
  };

  const handleNewThread = () => {
    setShowSquares(true);
    setSelectedText("");
  };

  return (
    <Col className="d-flex flex-column h-100">
      <Row
        className="flex-grow-1 overflow-y-scroll custom-sidebar g-0 pt-4"
        ref={containerRef}
      >
        <div
          className="flex-fill d-flex flex-column justify-content-end px-3"
          style={{ minHeight: "100%" }}
        >
          {showSquares && (
            <div className={styles.squareContainer}>
              {squareContent.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.square}`} // Apply .square class to all squares
                  onClick={() => handleSquareClick(item.text)}
                >
                  <i className={item.icon}></i>
                  <div className={styles.title}>{item.title}</div>{" "}
                  <div className={styles.text}>{item.text}</div>{" "}
                </div>
              ))}
            </div>
          )}
          <ChatItemsDisplay />
          <div ref={bottomRef} />
        </div>
      </Row>
      <Row className="g-0 px-3">
        <ChatInput
          filters={filters}
          selectedText={selectedText}
          onSend={handleSend}
        />
      </Row>
    </Col>
  );
};

export default Chat;
