import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Panel, PanelGroup } from "react-resizable-panels";
import Chat from "./Chatlegaloreima";
import { HandlePanel } from "../../../components/HandlePanel/HandlePanel";
import { ChatProvider } from "../../../contexts/ChatContext";
import useFilters from "../../../hooks/useOreimaFilters";
import Menu from "./Menu/Menu";
import SourceViewer from "./SourceViewer";

const LegalOreimaContent = () => {
  const filtersEndpoint = `${process.env.REACT_APP_API_URI}/legaloreima/filters`;
  const { filters, selectedFilters, setSelectedFilters } =
    useFilters(filtersEndpoint);

  return (
    <ChatProvider
      threadsEndpoint={`${process.env.REACT_APP_API_URI}/legaloreima/session_manager/thread_ids`}
      chatEndpoint={`${process.env.REACT_APP_API_URI}/legaloreima/chat`}
      messagesEndpoint={`${process.env.REACT_APP_API_URI}/legaloreima/session_manager/thread_history`}
      feedbackEndpoint={`${process.env.REACT_APP_API_URI}/legaloreima/session_manager/feedback_update`}
    >
      <Row id="container" className="h-100 mh-100 overflow-hidden p-0">
        <Col
          md={2}
          className="bg-light border-end height-full overflow-hidden p-0 mh-100 h-100"
          style={{ width: "fit-content" }}
          id="filters"
        >
          <Menu
            criterias={filters}
            filters={selectedFilters}
            setFilters={setSelectedFilters}
          />
        </Col>
        <Col className="d-flex flex-column mh-100 h-100 overflow-hidden">
          <Row className="flex-fill overflow-hidden">
            <PanelGroup
              autoSaveId="market"
              direction="horizontal"
              className="g-0"
            >
              <Panel defaultSizePercentage={70} minSizePercentage={30}>
                <Chat filters={selectedFilters} page="legaloreima" />
              </Panel>
              <HandlePanel />
              <Panel defaultSizePercentage={30} minSizePercentage={20}>
                <SourceViewer />
              </Panel>
            </PanelGroup>
          </Row>
        </Col>
      </Row>
    </ChatProvider>
  );
};

export default LegalOreimaContent;
