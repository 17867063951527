import Row from 'react-bootstrap/Row';
import './Home.scss'

function Home() {
    return (
        <Row className="landing-page">
            <div className="overlay">
                <div className="content">
                    <h1>Georges, your AI agent</h1>
                </div>
            </div>
        </Row>
    )
}

export default Home;