import { Carousel, ChatItem, Message } from "./types";

// Type guard to determine if a ChatItem is a Message
export function isMessage(item: ChatItem): item is Message {
  return (item as Message).content !== undefined;
}

// Type guard to determine if a ChatItem is a Carousel
export function isCarousel(item: ChatItem): item is Carousel {
  return (item as Carousel).type === "carousel";
}

export function isString(content: any): content is string {
  return typeof content === "string";
}

export function isError(value: unknown): value is Error {
  return value instanceof Error;
}

export function errorToString(error: unknown): string {
  if (isError(error)) {
    return error.message;
  } else if (error === null) {
    return "Null error";
  } else if (error === undefined) {
    return "Undefined error";
  } else if (typeof error === "object") {
    return JSON.stringify(error);
  } else {
    return String(error);
  }
}
