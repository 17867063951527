import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useRef, useState } from "react";
import { loginRequest } from "../config/authConfig";
import { useError } from "../contexts/ErrorContext";
import { keysToCamelCase, keysToSnakeCase } from "../utils/caseConversion";

/**
 * Custom hook for making authenticated HTTP requests using MSAL (Microsoft Authentication Library).
 * Manages authentication, request formatting, and response handling.
 */
const useFetchWithMsal = () => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { addError } = useError();

  // MSAL authentication result and any associated error
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      ...loginRequest,
      account: instance.getActiveAccount() || undefined,
    }
  );

  /**
   * Function to execute an authenticated HTTP request.
   * Handles request setup, execution, and response processing.
   * @param method - HTTP method (e.g., GET, POST)
   * @param endpoint - API endpoint URL
   * @param requestData - Optional data to include in the request body for POST requests
   * @returns The response data from the API
   */
  const execute = useCallback(
    async (method: string, endpoint: string, requestData: object = {}) => {
      // Abort if there is an MSAL error
      if (msalError) {
        setError(msalError);
        addError(`Authentication error: ${msalError.message}`);
        return;
      }

      // Proceed if authentication is successful
      if (result) {
        try {
          // Initialize abort controller to handle request cancellation
          abortControllerRef.current = new AbortController();
          const { signal } = abortControllerRef.current;

          // Set up headers for the request
          const headers = new Headers();
          headers.append("Authorization", `Bearer ${result.accessToken}`);
          headers.append("Access-Control-Expose-Headers", "");

          // Add userId to requestData if POST method and an active account exists
          const activeAccount = instance.getActiveAccount();
          if (method === "POST" && activeAccount) {
            (requestData as any)["userId"] = activeAccount.username;
          }

          // Convert requestData to snake_case for backend compatibility
          if (method === "POST" && requestData) {
            headers.append("Content-Type", "application/json");
            requestData = keysToSnakeCase(requestData);
          }

          // Configure request options
          const options: RequestInit = {
            method,
            headers,
            body:
              method === "POST" && requestData
                ? JSON.stringify(requestData)
                : null,
            signal,
          };
          console.log(options);

          setIsLoading(true); // Set loading state

          // Execute the HTTP request
          const response = await fetch(endpoint, options);
          let responseData = null;

          // Process the response based on its content type
          if (response) {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseType = response.headers.get("Content-Type");
            if (responseType === "application/json") {
              responseData = await response.json();
              responseData = keysToCamelCase(responseData); // Convert response keys to camelCase
            } else if (
              responseType &&
              ["application/pdf", "image/jpeg"].includes(responseType)
            ) {
              responseData = await response.blob(); // Handle binary responses (e.g., PDFs, images)
            }
            setData(responseData); // Store the response data
          }

          setIsLoading(false); // Reset loading state
          return responseData;
        } catch (e: any) {
          // Handle errors, including request aborts
          if (e.name === "AbortError") {
            console.log("Fetch aborted"); // Log abort events
          } else {
            setError(e); // Store and log errors
            console.error("Error", e);
          }
          setIsLoading(false); // Ensure loading state is reset
          throw e; // Re-throw the error for further handling
        } finally {
          // Clean up abort controller reference
          abortControllerRef.current = null;
        }
      }
    },
    [result, msalError, instance, addError] // Dependencies for useCallback
  );

  /**
   * Function to abort the ongoing request.
   */
  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the request if it is active
    }
  }, []);

  // Return the current state and functions for use in components
  return {
    isLoading,
    error,
    data,
    execute,
    abort,
  };
};

export default useFetchWithMsal;
