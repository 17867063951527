import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { isString } from "../../../types/typeGuards";
import { Message } from "../../../types/types";
import styles from "./ThoughtMessage.module.scss";
import MarkdownTable from "./components/MarkdownTable";
interface ThoughtMessageProps {
  message: Message;
}
const ThoughtMessage: React.FC<ThoughtMessageProps> = ({ message }) => {
  return (
    <div className={`d-flex  flex-row gap-2 ${styles.step}`}>
      <div className={`${styles.container}`}>
        {message && isString(message.content) && (
          <div className={`${styles.result}`}>
            <span className={styles.label}>
              {
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    table(props) {
                      return <MarkdownTable {...props} />;
                    },
                  }}
                >
                  {message.content}
                </Markdown>
              }
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThoughtMessage;
