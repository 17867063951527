import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { loginRequest } from "../../config/authConfig";
import LegalContent from "./components/LegalContentDefaultQuestions";

const Legal = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <LegalContent />
    </MsalAuthenticationTemplate>
  );
};
export default Legal;
