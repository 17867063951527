import { EventSourceMessage } from "@microsoft/fetch-event-source";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useError } from "../contexts/ErrorContext";
import { errorToString } from "../types/typeGuards";
import {
  Carousel,
  ChatItem,
  Message,
  MessageBuffer,
  parseMessage,
  parseThread,
  Source,
  Thread,
} from "../types/types";
import { keysToCamelCase } from "../utils/caseConversion";
import log from "../utils/logger";
import useSseWithMsal from "./useSseWithMsal";
import { useThread } from "./useThread";

export const useChat = (
  threadsEndpoint: string,
  messagesEndpoint: string,
  chatEndpoint: string
) => {
  // log.info("useChat hook initialized"); // Logging hook initialization

  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);
  const buffers = useRef<{ [key: string]: MessageBuffer }>({});
  const [chatItems, setChatItems] = useState<ChatItem[]>([]);
  const {
    threads,
    changeThread,
    selectedThread,
    messages: threadMessages,
    fetchMessages,
    setThreads,
  } = useThread(threadsEndpoint, messagesEndpoint);

  const { addError } = useError();

  // Handle source change
  const changeSelectedSource = useCallback(
    (source: Source) => {
      log.debug("Source changed", source);
      setSelectedSource(source);
    },
    [setSelectedSource]
  );

  const processMessage = useCallback((data: any[], message: Message) => {
    // Append or create a new buffer for the message
    if (buffers.current[message.id]) {
      log.debug(`Appending to buffer for message ID: ${message.id}`); // Log buffer append
      buffers.current[message.id].append(message);
    } else {
      log.debug(`Creating new buffer for message ID: ${message.id}`); // Log new buffer creation
      buffers.current[message.id] = new MessageBuffer(message);
    }

    const updatedMessages = [
      ...data.filter((m) => m.id !== message.id),
      buffers.current[message.id].message,
    ];
    log.debug("Updated messages", updatedMessages);
    return updatedMessages;
  }, []);

  const processThread = useCallback(
    (data: any[], thread: Thread) => {
      const t = threads.find((t) => t.id === thread.id);
      if (!t) {
        setThreads((threads) => [thread, ...threads]);
      }
      changeThread(thread, false);
      return data;
    },
    [changeThread, setThreads, threads]
  );

  // Handle incoming messages from the SSE
  const handleMessage = useCallback(
    (data: any[], event: EventSourceMessage) => {
      log.debug("handleMessage called with event:", event); // Log SSE event received
      log.debug("Current data is", data);
      try {
        const eventData = keysToCamelCase(JSON.parse(event.data));
        switch (eventData.objectType) {
          case "Message":
            const message = parseMessage(eventData.data);
            log.debug("Parsed message:", message); // Log the parsed message
            return processMessage(data, message);
          case "Thread":
            const thread = parseThread(eventData.data);
            log.debug("Parsed thread:", thread); // Log the parsed message
            return processThread(data, thread);
          default:
            return data;
        }
      } catch (error) {
        const errorMessage = errorToString(error);
        log.error("Error processing SSE message:", errorMessage);
        addError(`Error processing message: ${errorMessage}`);
        return data;
      }
    },
    [processMessage, processThread, addError]
  );

  // Transform the flat list of messages into a list of chat items (messages and carousels)
  const transformList = useCallback((messages: Message[]): ChatItem[] => {
    log.debug("Transforming messages into chat items"); // Log start of transformation

    const carousels: { [key: string]: Carousel } = {};
    const transformedList: ChatItem[] = [];

    messages.forEach((message) => {
      if (message.carousel) {
        const { carouselId, panelId } = message.carousel;

        if (!carousels[carouselId]) {
          carousels[carouselId] = {
            id: carouselId,
            type: "carousel",
            createdAt: message.createdAt,
            panels: [],
          };
        }

        let panel = carousels[carouselId].panels.find((p) => p.id === panelId);
        if (!panel) {
          log.debug(
            `Creating new panel in carousel ${carouselId} for panel ${panelId}`
          ); // Log panel creation
          panel = {
            id: panelId,
            title: message.carousel.panelLabel || "Untitled Panel",
            messages: [],
          };
          carousels[carouselId].panels.push(panel);
        }

        panel.messages.push(message);
      } else {
        transformedList.push(message);
      }
    });

    for (const carouselId in carousels) {
      log.debug(`Adding carousel ${carouselId} to transformed list`); // Log carousel addition
      transformedList.push(carousels[carouselId]);
    }

    log.debug("Transformed list created:", transformedList); // Log the transformed list
    return transformedList;
  }, []);

  // Hook for managing SSE connection and receiving messages
  const {
    execute: executeQuery,
    data: messagesQuery,
    abort: abortQuery,
    isLoading: isLoadingQuery,
  } = useSseWithMsal({ handleMessage });

  // Update messages state with new SSE messages
  useEffect(() => {
    log.debug("Updating messages state with new SSE messages"); // Log message update
    setMessages((prevMessages) => {
      const sseMessageIds = new Set(messagesQuery.map((obj) => obj.id));
      log.debug("SSE messages ID", sseMessageIds);
      const oldMessages = prevMessages.filter((m) => !sseMessageIds.has(m.id));
      log.debug("Old messages", oldMessages);
      return [...oldMessages, ...messagesQuery];
    });
  }, [messagesQuery]);

  // Transform messages into chat items and sort them by creation time
  useEffect(() => {
    log.debug("Transforming and sorting messages into chat items"); // Log transformation and sorting
    const allChatItems = transformList(messages);
    const sortedChatItems = allChatItems.sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
    );
    setChatItems(sortedChatItems);
    log.debug("Chat items updated in state"); // Log chat items update
  }, [messages, transformList]);

  // Function to send a custom payload to the backend
  const sendQuery = useCallback(
    async (payload: { query?: string; [key: string]: any }) => {
      log.info("Sending query to backend:", payload); // Log query send

      try {
        const threadId = selectedThread?.id || uuidv4();
        const finalPayload = {
          ...payload,
          threadId,
        };
        await executeQuery("POST", chatEndpoint, finalPayload);
      } catch (error) {
        const errorMessage = errorToString(error);
        log.error("Error sending query:", errorMessage);
        addError(`Failed to send query: ${errorMessage}`);
      }
    },
    [chatEndpoint, executeQuery, selectedThread, addError]
  );

  // Reset buffers and update message history when thread changes
  useEffect(() => {
    log.debug("Thread changed, resetting buffers and updating message history");
    // Reset buffers when the thread changes
    buffers.current = {};
    // Update messages with the messages from the selected thread
    setMessages(threadMessages);
  }, [threadMessages]);

  // log.info("useChat hook ready to use"); // Logging hook readiness
  log.debug("Current message buffers", buffers);
  log.debug("Current messages", messages);
  log.debug("Current chat items", chatItems);
  return {
    threads,
    changeThread,
    chatItems,
    sendQuery,
    abortQuery,
    isLoadingQuery,
    fetchMessages,
    selectedThread,
    selectedSource,
    changeSelectedSource,
  };
};
