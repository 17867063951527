import "bootstrap-icons/font/bootstrap-icons.css";
import { Element } from "hast";
import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { utils, writeFileXLSX } from "xlsx";
import styles from "./MarkdownTable.module.scss";

interface MarkdownTableProps {
  node?: Element;
}

const MarkdownTable = ({ node, ...rest }: MarkdownTableProps) => {
  const tableRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  const onDownload = () => {
    const wb = utils.table_to_book(tableRef.current);
    writeFileXLSX(wb, "Georges AI - export.xlsx");

    setAnimate(true);
    setTimeout(() => setAnimate(false), 1000);
  };

  return (
    <div className={styles.tableContainer}>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="tooltip-left">Export the table as an Excel file</Tooltip>
        }
        delay={{ show: 150, hide: 400 }}
      >
        <Button
          variant="light"
          className={styles.exportButton}
          onClick={onDownload}
        >
          <i className="bi bi-table"></i>
        </Button>
      </OverlayTrigger>

      <table
        ref={tableRef}
        className={`table table-striped table-hover ${
          animate ? styles.animateTable : ""
        }`}
        {...rest}
      ></table>
    </div>
  );
};

export default MarkdownTable;
