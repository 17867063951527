import React, { createContext, useContext } from "react";
import { useChat } from "../hooks/useChat";
import { ChatItem, Source, Thread } from "../types/types";

interface ChatContextProps {
  threads: Thread[]; // Replace with actual thread type
  changeThread: (thread: Thread | null, fetch?: boolean) => void;
  selectedThread: Thread | null;
  sendQuery: (payload: { query?: string; [key: string]: any }) => void;
  abortQuery: () => void;
  isLoadingQuery: boolean;
  chatItems: ChatItem[]; // Replace with actual chat item type
  fetchMessages: (threadId: string) => void;
  feedbackEndpoint: string;
  selectedSource: Source | null;
  changeSelectedSource: (source: Source) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider: React.FC<{
  children: React.ReactNode;
  threadsEndpoint: string;
  messagesEndpoint: string;
  chatEndpoint: string;
  feedbackEndpoint: string;
}> = ({
  children,
  threadsEndpoint,
  messagesEndpoint,
  chatEndpoint,
  feedbackEndpoint,
}) => {
  const chat = useChat(threadsEndpoint, messagesEndpoint, chatEndpoint);

  return (
    <ChatContext.Provider value={{ ...chat, feedbackEndpoint }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
