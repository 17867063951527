import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PrimeReactProvider } from "primereact/api";
import React from "react";
import "react-range-slider-input/dist/style.css";
import { Route, Routes } from "react-router-dom";
import "react-widgets/styles.css";
import "./App.scss";
import ErrorBanner from "./components/ErrorBanner";
import { PageLayout } from "./components/PageLayout";
import { ErrorProvider } from "./contexts/ErrorContext";
import Home from "./pages/Home/Home";
import Legal from "./pages/Legal/Legal";
import Market from "./pages/Market/Market";
import MyDeal from "./pages/MyDeal/MyDeal";
import LegalOreima from "./pages/LegalOreima/LegalOreima";

interface PagesProps {}

const Pages: React.FC<PagesProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/market" element={<Market />} />
      <Route path="/mydeal" element={<MyDeal />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/oreima" element={<LegalOreima />} />
    </Routes>
  );
};

interface AppProps {
  instance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <PrimeReactProvider>
        <ErrorProvider>
          <PageLayout>
            <ErrorBanner />
            <AuthenticatedTemplate>
              <Pages />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Home />
            </UnauthenticatedTemplate>
          </PageLayout>
        </ErrorProvider>
      </PrimeReactProvider>
    </MsalProvider>
  );
};

export default App;
