import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useError } from "../contexts/ErrorContext";

const ErrorBanner: React.FC = () => {
  const { errors, removeError } = useError();

  return (
    <ToastContainer
      position="top-center"
      className="p-3"
      style={{ zIndex: 1100 }}
    >
      {errors.map((error, index) => (
        <Toast key={index} onClose={() => removeError(index)} bg="danger">
          <Toast.Header closeButton={false} className="bg-danger">
            <strong className="me-auto">Error</strong>
            <button
              type="button"
              className="btn-close"
              onClick={() => removeError(index)}
            ></button>
          </Toast.Header>
          <Toast.Body>{error}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ErrorBanner;
