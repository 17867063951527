import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { isString } from "../../../types/typeGuards";
import { Message } from "../../../types/types";
import MarkdownTable from "./components/MarkdownTable";
import styles from "./UserMessage.module.scss";

interface UserMessageProps {
  message: Message;
}

const UserMessage: React.FC<UserMessageProps> = ({ message }) => {
  return (
    <div className={`d-flex flex-column flex-fill ${styles.message}`}>
      <div className={`d-flex flex-row justify-content-end`}>
        <div className={`${styles.result} d-flex flex-column`}>
          {message && isString(message.content) && (
            <div className={`align-self-center`}>
              <Markdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{ table: MarkdownTable }}
              >
                {message.content}
              </Markdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
