import Filters from "./components/Filters/Filters"; // Import Filters component for managing document filters
import styles from "./Menu.module.scss"; // Import styles for the Menu component
import { useState } from "react"; // Import useState hook for managing component state
import Threads from "../../../../components/Chat/Threads"; // Import Threads component for displaying chat history
import { useChatContext } from "../../../../contexts/ChatContext"; // Import context for chat functionality

// Menu component to manage filters and chat history
function Menu({ criterias, filters, setFilters }) {
  const [activeTab, setActiveTab] = useState(0); // State to track the currently active tab (0: Filters, 1: Chat History)
  const [collapse, setCollapse] = useState(false); // State to manage menu collapse/uncollapse
  const { changeThread } = useChatContext(); // Destructure changeThread function from chat context

  // Function to set the active tab based on user selection
  const handleActiveTab = (tabIdx) => {
    setActiveTab(tabIdx);
  };

  // Function to collapse the menu
  const handleCollapseMenu = () => {
    setCollapse(true);
  };

  // Function to uncollapse the menu
  const handleUncollapseMenu = () => {
    setCollapse(false);
  };

  // Function to handle creating a new thread and resetting the active tab
  const handleNewThread = () => {
    changeThread(null); // Reset the current thread
    setActiveTab(0); // Switch to Chat History tab
  };

  return (
    <div
      className={`${styles.menuContent} d-flex flex-column p-2 gap-2 h-100`}
      style={{
        width: !collapse && "250px", // Set width based on collapse state
      }}
    >
      {collapse ? ( // Render collapse button if menu is collapsed
        <div
          className={`align-self-end  ${styles.collapseButton} m-2`}
          onClick={handleUncollapseMenu} // Uncollapse menu on click
          style={{ writingMode: "tb-rl" }} // Vertical text for the button
        >
          <i className="bi bi-arrow-bar-right"></i> Menu
        </div>
      ) : (
        <>
          <button
            className={`${styles.newThreadButton} align-self-start mb-1`}
            onClick={handleNewThread} // Create new thread on click
          >
            <i className="bi bi-plus-circle-dotted"></i> New thread
          </button>

          <div className="d-flex flex-row width-full justify-content-center gap-1 align-content-center px-2">
            <div
              className={`${styles.tab} ${activeTab === 0 && styles.tabActive}`} // Active tab styling
              onClick={() => handleActiveTab(0)} // Switch to Document Filters tab
            >
              Document Filters
            </div>
            <div
              className={`${styles.tab} ${
                activeTab === 1 && styles.tabActive
              } `}
              onClick={() => handleActiveTab(1)} // Switch to Chat History tab
            >
              Chat <br /> History
            </div>
            <div
              className={`align-self-end d-flex flex-fill align-content-center  ${styles.tab} ${styles.collapseButton}`}
              style={{
                width: "20px", // Fixed width for collapse button
                alignItems: "center",
                justifyItems: "center",
              }}
              onClick={handleCollapseMenu} // Collapse menu on click
            >
              <i className="bi bi-arrow-bar-left"></i>
            </div>
          </div>
          <div
            className={
              "d-flex flex-column flex-grow-1 width-full overflow-y-scroll custom-sidebar"
            }
          >
            {activeTab === 0 && ( // Render Filters component if Document Filters tab is active
              <Filters
                criterias={criterias} // Pass criterias to Filters
                filters={filters} // Pass filters to Filters
                setFilters={setFilters} // Pass setFilters function to Filters
              />
            )}
            {activeTab === 1 && <Threads />}
          </div>
        </>
      )}
    </div>
  );
}

export default Menu; // Export Menu component for use in other parts of the application
