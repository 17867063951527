import React, { useCallback, useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Panel, PanelGroup } from "react-resizable-panels";
import Chat from "./Chatmarket"; // Updated path to use Chatmarket.tsx
import { HandlePanel } from "../../../components/HandlePanel/HandlePanel";
import { ChatProvider } from "../../../contexts/ChatContext";
import { useError } from "../../../contexts/ErrorContext";
import useFetchWithMsal from "../../../hooks/useFetchWithMsal";
import { errorToString } from "../../../types/typeGuards";
import Menu from "./Menu/Menu";
import SourceViewer from "./SourceViewer";
import content from "../../../data/textboxContent.json";

function MarketContent() {
  const { execute: executeFetch } = useFetchWithMsal();
  const { addError } = useError();
  const [sectorLoaded, setSectorLoaded] = useState(false);
  const [criterias, setCriterias] = useState({
    sector: [],
    macroIndustry: [],
    subIndustry: [],
    projectName: [],
    type: [],
    year: [],
  });
  const [filters, setFilters] = useState({
    sectors: [],
    macroIndustry: [],
    subIndustry: [],
    projectName: [],
    type: [],
    year: [],
  });
  const [selectedText, setSelectedText] = useState("");
  const [selectedMode, setSelectedMode] = useState("UNDEFINED");

  const initiateSectors = useCallback(async () => {
    try {
      const data = await executeFetch(
        "GET",
        `${process.env.REACT_APP_API_URI}/market/filters`
      );
      console.log("received data:", data);
      setCriterias(data || {});
    } catch (error) {
      const errorMessage = errorToString(error);
      console.error("Error fetching market filters:", errorMessage);
      addError(`Failed to fetch market filters: ${errorMessage}`);
    }
  }, [executeFetch, addError]);

  useEffect(() => {
    if (!sectorLoaded) {
      const timer = setTimeout(() => {
        initiateSectors();
        setSectorLoaded(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [sectorLoaded, initiateSectors]);

  // Memoize the endpoint URLs
  const endpoints = useMemo(
    () => ({
      threadsEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/thread_ids`,
      chatEndpoint: `${process.env.REACT_APP_API_URI}/market/chat`,
      messagesEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/thread_history`,
      feedbackEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/feedback_update`,
    }),
    []
  );

  const handleSquareClick = (
    text: string,
    mode: string,
    newFilters: object
  ) => {
    setSelectedText(text);
    setSelectedMode(mode);

    // Safely access the filters from newFilters
    const squareFilters = newFilters || {}; // Default to an empty object if filters is undefined

    // Update filters only if they exist
    setFilters((prevFilters) => {
      console.log("Previous filters:", prevFilters); // Log the previous filters
      const updatedFilters = { ...prevFilters };

      // Loop through each key in squareFilters and update the corresponding filter
      Object.keys(squareFilters).forEach((key) => {
        if (Array.isArray(squareFilters[key])) {
          updatedFilters[key] = squareFilters[key]; // Set the filter if it exists
        }
      });

      console.log("Updated filters:", updatedFilters); // Log the updated filters

      return updatedFilters; // Return the updated filters
    });
  };

  const pageContent = useMemo(() => content["market"], []);

  const groupedContent = useMemo(() => {
    return pageContent.containers.reduce((acc, container) => {
      acc[container.key] = {
        title: container.title,
        tooltip: container.tooltip,
        items: container.squares,
      };
      return acc;
    }, {});
  }, [pageContent]);

  const modeOptions = useMemo(() => {
    return pageContent.containers.map((container) => ({
      value: container.modeValue,
      icon: container.icon,
      label: container.label,
      tooltip: container.tooltip,
    }));
  }, [pageContent]);

  console.log("Rendering MarketContent"); // Added this line

  return (
    <ChatProvider {...endpoints}>
      <Row id="container" className="h-100 mh-100 overflow-hidden p-0">
        <Col
          md={2}
          className="bg-light border-end height-full overflow-hidden p-0 mh-100 h-100"
          style={{ width: "fit-content" }}
          id="filters"
        >
          <Menu
            criterias={criterias}
            filters={filters}
            setFilters={setFilters}
          />
        </Col>

        <Col className="d-flex flex-column mh-100 h-100 overflow-hidden">
          <Row className="flex-fill overflow-hidden">
            <PanelGroup
              autoSaveId="market"
              direction="horizontal"
              className="g-0"
            >
              <Panel defaultSizePercentage={70} minSizePercentage={30}>
                <Chat
                  filters={filters}
                  page="market"
                  selectedText={selectedText}
                  selectedMode={selectedMode}
                  setSelectedText={setSelectedText}
                  setSelectedMode={setSelectedMode}
                  groupedContent={groupedContent}
                  modeOptions={modeOptions}
                  onSquareClick={handleSquareClick}
                />
              </Panel>
              <HandlePanel />
              <Panel defaultSizePercentage={30} minSizePercentage={20}>
                <Col className="d-flex flex-column h-100">
                  <SourceViewer />
                </Col>
              </Panel>
            </PanelGroup>
          </Row>
        </Col>
      </Row>
    </ChatProvider>
  );
}

export default MarketContent;
