import React, { useEffect, useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap"; // Import Button from react-bootstrap
import { useChatContext } from "../../../contexts/ChatContext"; // Updated path
import useAutoScroll from "../../../hooks/useAutoScroll"; // Updated path
import ChatInput from "./ChatInputmarket"; // Updated path
import ChatItemsDisplay from "../../../components/Chat/ChatItemsDisplay"; // Corrected path
import content from "../../../data/textboxContent.json"; // Updated path
import ExampleSquares from "../../../components/Chat/ExampleSquares/ExampleSquares"; // Added import

interface ChatProps {
  filters: object;
  page: "market" | "legal" | "mydeal";
  selectedText: string;
  selectedMode: string;
  setSelectedText: (text: string) => void;
  setSelectedMode: (mode: string) => void;
  groupedContent: any;
  modeOptions: any[];
  onSquareClick: (text: string, mode: string, newFilters: object) => void;
}

const Chat: React.FC<ChatProps> = ({
  filters,
  page,
  selectedText,
  selectedMode,
  setSelectedText,
  setSelectedMode,
  groupedContent: propsGroupedContent,
  modeOptions: propsModeOptions,
  onSquareClick,
}) => {
  const { chatItems } = useChatContext();
  const { containerRef, bottomRef, isUserAtBottom, scrollToBottom } =
    useAutoScroll();
  const [showSquares, setShowSquares] = useState(true);

  useEffect(() => {
    if (isUserAtBottom) scrollToBottom();
    setShowSquares(chatItems.length === 0);
  }, [chatItems, scrollToBottom, isUserAtBottom]);

  const pageContent = useMemo(() => content[page], [page]);

  const groupedContent = useMemo(() => {
    return pageContent.containers.reduce((acc, container) => {
      acc[container.key] = {
        title: container.title,
        tooltip: container.tooltip,
        items: container.squares,
      };
      return acc;
    }, {});
  }, [pageContent]);

  const modeOptions = useMemo(() => {
    return pageContent.containers.map((container) => ({
      value: container.modeValue,
      icon: container.icon,
      label: container.label,
      tooltip: container.tooltip,
    }));
  }, [pageContent]);

  const handleModeChange = (mode: string) => {
    setSelectedMode(mode);
  };

  const handleSend = () => {
    setShowSquares(false);
  };

  return (
    <Col className="d-flex flex-column h-100">
      <Row
        className="flex-grow-1 overflow-y-scroll custom-sidebar g-0 pt-4"
        ref={containerRef}
      >
        <div
          className="flex-fill d-flex flex-column justify-content-end px-3"
          style={{ minHeight: "100%" }}
        >
          {showSquares && (
            <ExampleSquares
              groupedContent={propsGroupedContent}
              onSquareClick={onSquareClick}
            />
          )}
          <ChatItemsDisplay />
          <div ref={bottomRef} />
        </div>
      </Row>
      <Row className="g-0 px-3">
        <ChatInput
          filters={filters}
          selectedText={selectedText}
          selectedMode={selectedMode}
          onSend={handleSend}
          onModeChange={handleModeChange}
          modeOptions={propsModeOptions}
        />
      </Row>
    </Col>
  );
};

export default Chat;
