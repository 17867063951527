import Filters from "./components/Filters/Filters";
import styles from "./Menu.module.scss";
import { useState } from "react";
import Threads from "../../../../components/Chat/Threads";
import { useChatContext } from "../../../../contexts/ChatContext";

function Menu({ criterias, filters, setFilters }) {
  const [activeTab, setActiveTab] = useState(0);
  const [collapse, setCollapse] = useState(false);
  const { changeThread } = useChatContext();
  const handleActiveTab = (tabIdx) => {
    setActiveTab(tabIdx);
  };

  const handleCollapseMenu = () => {
    setCollapse(true);
  };

  const handleUncollapseMenu = () => {
    setCollapse(false);
  };

  const handleNewThread = () => {
    changeThread(null);
    setActiveTab(0); // Switch to Chat History tab
  };

  return (
    <div
      className={`${styles.menuContent} d-flex flex-column p-2 gap-2 h-100`}
      style={{ width: !collapse && "250px" }}
    >
      {collapse ? (
        <div
          className={`align-self-end  ${styles.collapseButton} m-2`}
          onClick={handleUncollapseMenu}
          style={{ writingMode: "tb-rl" }}
        >
          <i className="bi bi-arrow-bar-right"></i> Menu
        </div>
      ) : (
        <div className="d-flex flex-column h-100">
          <button
            className={`${styles.newThreadButton} align-self-start mb-1`}
            onClick={handleNewThread}
          >
            <i className="bi bi-plus-circle-dotted"></i> New thread
          </button>
          <div className="d-flex flex-row width-full justify-content-center gap-1 align-content-center px-2 pt-2">
            <div
              className={`${styles.tab} ${activeTab === 0 && styles.tabActive}`}
              onClick={() => handleActiveTab(0)}
            >
              Document Filters
            </div>
            <div
              className={`${styles.tab} ${
                activeTab === 1 && styles.tabActive
              } `}
              onClick={() => handleActiveTab(1)}
            >
              Chat <br /> History
            </div>
            <div
              className={`align-self-end d-flex flex-fill align-content-center  ${styles.tab} ${styles.collapseButton}`}
              style={{
                width: "20px",
                alignItems: "center",
                justifyItems: "center",
              }}
              onClick={handleCollapseMenu}
            >
              <i className="bi bi-arrow-bar-left"></i>
            </div>
          </div>
          <div
            className={
              "d-flex flex-column flex-grow-1 width-full overflow-y-scroll custom-sidebar"
            }
          >
            {activeTab === 0 && (
              <Filters
                criterias={criterias}
                filters={filters}
                setFilters={setFilters}
              />
            )}
            {activeTab === 1 && <Threads />}
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
