import { ReactNode } from "react";
import Container from "react-bootstrap/Container";
import { NavigationBar } from "./NavigationBar";

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div
      className="d-flex flex-column"
      style={{ height: "100vh", maxHeight: "100vh" }}
    >
      <NavigationBar />
      <Container
        fluid
        className="d-flex flex-column flex-fill"
        style={{ overflow: "hidden" }}
      >
        {children}
      </Container>
    </div>
  );
};
