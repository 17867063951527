import { useCallback, useEffect, useState } from "react";
import { useError } from "../contexts/ErrorContext";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { errorToString } from "../types/typeGuards";
import mainFilterConfig from "../data/mainFilters.json";
import dealFilterConfig from "../data/dealFilters.json";
import docFilterConfig from "../data/docFilters.json";

const useFilters = (endpoint: string) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filters, setFilters] = useState({});
  const { execute: executeFetch } = useFetchWithMsal();
  const { addError } = useError();

  const getDefaultFilters = useCallback(() => {
    const allConfigs = [...mainFilterConfig, ...dealFilterConfig, ...docFilterConfig];
    const defaultFilters: Record<string, any> = {};

    allConfigs.forEach(filter => {
      if ('default' in filter && filter.default !== undefined) {
        defaultFilters[filter.id] = filter.default;
      }
    });

    return defaultFilters;
  }, []);

  // Set default filters on initial render
  useEffect(() => {
    setSelectedFilters(getDefaultFilters());
  }, [getDefaultFilters]);

  const fetchFilters = useCallback(async () => {
    try {
      const data = await executeFetch("POST", endpoint, {
        selectedFilters,
      });
      setFilters((prevFilters) => ({ ...prevFilters, ...data }));
    } catch (error) {
      const errorMessage = errorToString(error);
      console.error("Error fetching filters:", errorMessage);
      addError(`Failed to fetch filters: ${errorMessage}`);
    }
  }, [executeFetch, selectedFilters, addError, endpoint]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchFilters();
    }, 500);

    return () => clearTimeout(timer);
  }, [fetchFilters]);

  useEffect(() => {
    fetchFilters();
  }, [selectedFilters, fetchFilters]);

  return { filters, selectedFilters, setSelectedFilters, fetchFilters };
};

export default useFilters;
