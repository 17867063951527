import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./ExampleSquares.module.scss";

interface SquareItem {
  text: string;
  mode: string;
  icon: string;
  title: string;
  filters?: {
    [key: string]: string[];
  };
}

interface GroupedContent {
  [key: string]: {
    title: string;
    tooltip: string;
    items: SquareItem[];
  };
}

interface ExampleSquaresProps {
  groupedContent: GroupedContent;
  onSquareClick: (text: string, mode: string, newFilters: object) => void;
}

const ExampleSquares: React.FC<ExampleSquaresProps> = ({
  groupedContent,
  onSquareClick,
}) => {
  return (
    <div className={styles.containerWrapper}>
      {Object.entries(groupedContent).map(
        ([key, { title, tooltip, items }]) => (
          <div key={key} className={styles.container}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${key}`} className={styles.tooltip}>
                  {tooltip}
                </Tooltip>
              }
            >
              <span className={styles.infoIcon}>
                <i className="bi bi-info-circle"></i>
              </span>
            </OverlayTrigger>
            <h3 className={styles.containerTitle}>{title}</h3>
            {items.map((item, index) => (
              <Button
                key={index}
                className={styles.square}
                onClick={() =>
                  onSquareClick(item.text, item.mode, item.filters || {})
                } // Ensure filters are passed correctly
              >
                <div className={styles.header}>
                  <i className={item.icon}></i>
                  <div className={styles.title}>{item.title}</div>
                </div>
                <div className={styles.text}>{item.text}</div>
              </Button>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default ExampleSquares;
