import { MultiSelect } from "primereact/multiselect";
import React, { useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./Filters.module.scss";

interface MarketFilters {
  sector: string[];
  macroIndustry: string[];
  subIndustry: string[];
  projectName: string[];
  year: string[];
  type: string[];
}
interface FiltersProps {
  criterias: MarketFilters;
  filters: MarketFilters;
  setFilters: (filters: MarketFilters) => void;
}

const Filters: React.FC<FiltersProps> = ({
  criterias,
  filters,
  setFilters,
}) => {
  const getActiveMacroIndustry = (
    filters: MarketFilters,
    criterias: MarketFilters
  ) => {
    const selectedSectors = filters.sector || []; // Default to an empty array if undefined

    // Define filtering criteria based on selected sectors
    const sectorFilters: { [key: string]: (idNumber: number) => boolean } = {
      Infrastructure: (idNumber) => idNumber < 20, // Filter for ID numbers below 20
      Growth: (idNumber) => idNumber >= 20 && idNumber < 40,
      "Real estate": (idNumber) => idNumber >= 40 && idNumber < 60,
      "Critical metals": (idNumber) => idNumber >= 100, // Filter for ID numbers 100 or more
      // Add more sectors and their corresponding filter functions here
    };

    // Initialize an array to hold the filtered macro industries
    let filteredMacroIndustries: string[] = [];

    // Iterate over selected sectors and apply the corresponding filters
    selectedSectors.forEach((sector) => {
      if (sectorFilters[sector]) {
        const filterFunction = sectorFilters[sector];
        const filtered = criterias.macroIndustry.filter((macroIndustry) => {
          const match = macroIndustry.match(/\d+/); // Match one or more digits
          if (match) {
            const idNumber = parseInt(match[0], 10); // Convert the matched string to a number
            return filterFunction(idNumber); // Apply the specific filter for the sector
          }
          return false; // If no match, exclude this macro industry
        });
        filteredMacroIndustries = [...filteredMacroIndustries, ...filtered]; // Combine results
      }
    });

    return filteredMacroIndustries; // Return the filtered macro industries
  };

  const getActiveSubIndustry = (filters: MarketFilters) => {
    if (Array.isArray(filters.macroIndustry)) {
      const idx = filters.macroIndustry.map((mi) => mi.split(".")[0]);
      const sub_indus = criterias.subIndustry.filter((si) =>
        idx.includes(si.split(".")[0])
      );
      return sub_indus.sort() || [];
    } else return [];
  };

  const getActiveProjectName = (filters: MarketFilters) => {
    if (Array.isArray(filters.subIndustry)) {
      const idx = filters.subIndustry.map((si) =>
        si.split(".").splice(0, 2).join(".")
      );
      const project = criterias.projectName.filter(
        (p) =>
          idx.includes(p.split(".").splice(0, 2).join(".")) || p === "99. NA"
      );

      return project.sort() || [];
    } else return [];
  };

  const getActiveYear = (filters: MarketFilters) => {
    if (Array.isArray(filters.projectName)) {
      const yearPattern = /[0-9]+\.[0-9]+\.\s+([0-9]+)\s.*/i;
      const idx = filters.projectName
        .map((si) => {
          const match = yearPattern.exec(si);
          return match ? match[1] : null; // Check if match is not null
        })
        .filter(Boolean);
      const year = criterias.year.filter((y) => idx.includes(y));

      return year.sort() || ["N.A"];
    } else return ["N.A"];
  };

  useEffect(() => {
    if (Array.isArray(filters.macroIndustry)) {
      const idx = filters.macroIndustry.map((mi) => mi.split(".")[0]);
      const sub_indus = criterias.subIndustry.filter((si) =>
        idx.includes(si.split(".")[0])
      );
      setFilters({ ...filters, subIndustry: sub_indus });
    }
  }, [filters.macroIndustry]);

  useEffect(() => {
    if (Array.isArray(filters.subIndustry)) {
      const idx = filters.subIndustry.map((si) =>
        si.split(".").splice(0, 2).join(".")
      );
      const project = criterias.projectName.filter((p) =>
        idx.includes(p.split(".").splice(0, 2).join("."))
      );
      setFilters({ ...filters, projectName: project });
    }
  }, [filters.subIndustry]);

  useEffect(() => {
    if (Array.isArray(filters.projectName)) {
      const yearPattern = /[0-9]+\.[0-9]+\.\s+([0-9]+)\s.*/i;
      const idx = filters.projectName
        .map((si) => {
          const match = yearPattern.exec(si);
          return match ? match[1] : null; // Check if match is not null
        })
        .filter(Boolean);

      const year = criterias.year.filter((y) => idx.includes(y));
      setFilters({ ...filters, year: year });
    }
  }, [filters.projectName]);

  useEffect(() => {
    const activeMacroIndustries = getActiveMacroIndustry(filters, criterias);
    setFilters({ ...filters, macroIndustry: activeMacroIndustries });
  }, [filters.sector]);

  return (
    <div className="d-flex flex-column width-full p-2">
      <Form>
        {/* New MultiSelect for Sector with Tooltip */}
        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-macro-industry">
                Choose the strategy of your project
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Strategy</Form.Label>
          <MultiSelect
            options={
              criterias?.sector && Array.isArray(criterias.sector)
                ? criterias.sector.map((sector) => ({
                    label: sector,
                    value: sector,
                  })) // Map to the required format
                : []
            }
            value={filters.sector} // Bind to the filters state
            onChange={
              (e) => setFilters({ ...filters, sector: e.value }) // Update selected sectors
            }
            // placeholder="Select sectors"
            filter
            display="chip"
            scrollHeight="300px"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }} // Ensure it matches the width of other selects
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-macro-industry">
                Choose the macro industry/industries of the projects that you
                want to search. For example 1. Digital or 2. Energy
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Macro Industry</Form.Label>
          <MultiSelect
            options={
              // criterias?.macroIndustry && Array.isArray(criterias.macroIndustry)
              //   ? (() => {
              //       // Sort macro industries numerically
              //       const sortedOptions = [...criterias.macroIndustry].sort(
              //         (a, b) => {
              //           const numA = parseFloat(a.split(".")[0]);
              //           const numB = parseFloat(b.split(".")[0]);
              //           return numA - numB; // Compare numeric parts
              //         }
              //       );
              //       return sortedOptions; // Return sorted options
              //     })()
              //   : []
              getActiveMacroIndustry(filters, criterias)
            }
            value={filters.macroIndustry}
            onChange={
              (selected) =>
                setFilters({ ...filters, macroIndustry: selected.value }) // Update selected macro industries
            }
            placeholder=""
            filter
            display="chip"
            scrollHeight="300px"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-sub-industry">
                Choose the sub-industry/industries of the projects that you want
                to search. For example 1.1 Towers and Wireless or 2.3 E-fuels
                and H2
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Sub-Industry</Form.Label>
          <MultiSelect
            disabled={
              criterias?.subIndustry && Array.isArray(criterias.subIndustry)
                ? false
                : true // Disable if no sub-industries available
            }
            options={getActiveSubIndustry(filters)} // Get active sub-industries
            // options={["test"]}
            value={filters.subIndustry}
            onChange={
              (selected) =>
                setFilters({ ...filters, subIndustry: selected.value }) // Update selected sub-industries
            }
            placeholder=""
            filter
            display="chip"
            scrollHeight="300px"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-year">
                Choose the year/s of the project
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Project Year</Form.Label>
          <MultiSelect
            disabled={
              criterias?.year && Array.isArray(criterias.year) ? false : true // Disable if no years available
            }
            options={getActiveYear(filters)} // Get active years
            value={filters.year}
            onChange={
              (selected) => setFilters({ ...filters, year: selected.value }) // Update selected years
            }
            scrollHeight="300px"
            placeholder=""
            filter
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-project-type">
                Choose the type/s of document that you want to search. For
                example CDD, DD, IM etc
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Document Type</Form.Label>
          <MultiSelect
            disabled={
              criterias?.type && Array.isArray(criterias.type) ? false : true // Disable if no document types available
            }
            options={
              criterias?.type && Array.isArray(criterias.type)
                ? criterias.type.sort() // Sort document types
                : []
            }
            value={filters.type}
            onChange={
              (selected) => setFilters({ ...filters, type: selected.value }) // Update selected document types
            }
            placeholder=""
            scrollHeight="300px"
            filter
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-project-name">
                Choose the name/s of your projects
              </Tooltip>
            }
            delay={{ show: 150, hide: 200 }}
          >
            <span className={styles.infoIconWrapper}>
              <span className={styles.infoIcon}>
                <i className="bi"></i>
              </span>
            </span>
          </OverlayTrigger>
          <Form.Label style={{ fontSize: "0.95em" }}>Project Name</Form.Label>
          <MultiSelect
            disabled={
              criterias?.projectName && Array.isArray(criterias.projectName)
                ? false
                : true // Disable if no project names available
            }
            options={getActiveProjectName(filters)} // Get active project names
            value={filters.projectName}
            onChange={
              (selected) =>
                setFilters({ ...filters, projectName: selected.value }) // Update selected project names
            }
            placeholder=""
            filter
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default Filters;
