import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { loginRequest } from "../../config/authConfig";
import styles from "./SignInButton.module.scss"; // Import the SCSS module

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Button
      className={styles.signInButton}
      variant="outline-secondary"
      onClick={handleLogin}
    >
      Sign in
    </Button>
  );
};
