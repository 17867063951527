import React, { useEffect, useState, useMemo } from "react"; // React and hooks for state and effects
import { Col, Row } from "react-bootstrap"; // Bootstrap components for layout
import { useChatContext } from "../../../contexts/ChatContext"; // Custom hook for chat context
import useAutoScroll from "../../../hooks/useAutoScroll"; // Custom hook for auto-scrolling functionality
import ChatInput from "./ChatInputlegal"; // Import the ChatInput component for user input
import ChatItemsDisplay from "../../../components/Chat/ChatItemsDisplay"; // Component to display chat items
import content from "../../../data/textboxContent.json"; // JSON data for chat content
import ExampleSquares from "../../../components/Chat/ExampleSquares/ExampleSquares"; // Component for displaying example squares

// Define the props interface for the Chat component
interface ChatProps {
  filters: object; // Filters to apply to chat items
  page: "market" | "legal" | "mydeal"; // Current page context
  selectedText: string; // Selected text
  selectedMode: string; // Selected mode
  setSelectedText: (text: string) => void; // Function to set selected text
  setSelectedMode: (mode: string) => void; // Function to set selected mode
  groupedContent: any; // Grouped content
  modeOptions: any[]; // Mode options
  onSquareClick: (text: string, mode: string, newFilters: object) => void; // Function to handle square clicks
}

// Functional component for the chat interface
const Chat: React.FC<ChatProps> = ({
  filters,
  page,
  selectedText,
  selectedMode,
  setSelectedText,
  setSelectedMode,
  groupedContent: propsGroupedContent, // Rename the prop to avoid conflict
  modeOptions: propsModeOptions, // Rename the prop to avoid conflict
  onSquareClick,
}) => {
  const { chatItems } = useChatContext(); // Get chat items from context
  const { containerRef, bottomRef, isUserAtBottom, scrollToBottom } =
    useAutoScroll(); // Auto-scroll hooks
  const [showSquares, setShowSquares] = useState(true); // State to control visibility of example squares

  // Effect to scroll to the bottom and show/hide squares based on chat items
  useEffect(() => {
    if (isUserAtBottom) scrollToBottom(); // Scroll to bottom if user is at the bottom
    setShowSquares(chatItems.length === 0); // Show squares if no chat items are present
  }, [chatItems, scrollToBottom, isUserAtBottom]);

  // Memoized content based on the current page
  const pageContent = useMemo(() => content[page], [page]);

  // Group content into a structured format for easier access
  const groupedContent = useMemo(() => {
    return pageContent.containers.reduce((acc, container) => {
      acc[container.key] = {
        title: container.title, // Title of the container
        tooltip: container.tooltip, // Tooltip for the container
        items: container.squares, // Items in the container
      };
      return acc; // Return the accumulated object
    }, {});
  }, [pageContent]);

  // Prepare mode options for the chat input
  const modeOptions = useMemo(() => {
    return pageContent.containers.map((container) => ({
      value: container.modeValue, // Value for the mode
      icon: container.icon, // Icon associated with the mode
      label: container.label, // Display label for the mode
      tooltip: container.tooltip, // Tooltip text for the mode
    }));
  }, [pageContent]);

  // Function to handle mode changes
  const handleModeChange = (mode: string) => {
    setSelectedMode(mode); // Update the selected mode
  };

  // Function to handle sending messages
  const handleSend = () => {
    setShowSquares(false); // Hide squares when sending a message
  };

  return (
    <Col className="d-flex flex-column h-100">
      <Row
        className="flex-grow-1 overflow-y-scroll custom-sidebar g-0 pt-4"
        ref={containerRef} // Reference for auto-scrolling
      >
        <div
          className="flex-fill d-flex flex-column justify-content-end px-3"
          style={{ minHeight: "100%" }} // Ensure minimum height for the chat area
        >
          {showSquares && ( // Conditionally render example squares
            <ExampleSquares
              groupedContent={propsGroupedContent} // Use the renamed prop
              onSquareClick={onSquareClick} // Handle square click events
            />
          )}
          <ChatItemsDisplay /> {/* Display chat items */}
          <div ref={bottomRef} /> {/* Reference for scrolling to the bottom */}
        </div>
      </Row>
      <Row className="g-0 px-3">
        <ChatInput
          filters={filters} // Pass filters to ChatInput
          selectedText={selectedText} // Pass selected text to ChatInput
          selectedMode={selectedMode} // Pass selected mode to ChatInput
          onSend={handleSend} // Handle send action
          onModeChange={handleModeChange} // Handle mode change
          modeOptions={propsModeOptions} // Pass the renamed mode options to ChatInput
        />
      </Row>
    </Col>
  );
};

export default Chat; // Export the Chat component
