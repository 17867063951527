import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"; // Import OverlayTrigger and Tooltip for UI elements
import { useChatContext } from "../../../contexts/ChatContext"; // Custom hook for chat context
import styles from "./ChatInputmarket.module.scss"; // Import the SCSS module for styling

// Define the props interface for the ChatInput component
interface ChatInputProps {
  filters: object; // Filters to apply to the chat query
  selectedText: string; // Text selected by the user
  selectedMode: string; // Current mode of the chat input
  onSend: () => void; // Callback function to handle sending the query
  onModeChange: (mode: string) => void; // Callback for changing the mode
  animateButton: boolean; // Flag to control button animation
  modeOptions: Array<{
    value: string; // Value of the mode
    icon: string; // Icon associated with the mode
    label: string; // Display label for the mode
    tooltip: string; // Tooltip text for the mode
  }>; // Array of mode options
}

// Functional component for chat input
const ChatInput: React.FC<ChatInputProps> = ({
  filters,
  selectedText,
  selectedMode,
  onSend,
  onModeChange,
  animateButton, // Destructure the new prop for animation
  modeOptions,
}) => {
  const [query, setQuery] = useState<string>(""); // State to hold the user's query
  const { sendQuery, abortQuery, isLoadingQuery } = useChatContext(); // Destructure chat context methods
  const inputRef = useRef<HTMLTextAreaElement>(null); // Reference for the input textarea

  // Effect to set the query when selectedText changes
  useEffect(() => {
    if (selectedText) {
      setQuery(selectedText); // Update query with selected text
    }
  }, [selectedText]);

  // Function to handle sending the query
  const handleQuery = (questionType: string) => {
    if (query.trim()) {
      // Check if the query is not empty
      const { sector, ...filteredFilters } = filters; // Exclude the sector filter
      const payload = {
        query,
        filters: filteredFilters,
        question_type: questionType,
      }; // Prepare payload
      sendQuery(payload); // Send the query
      setQuery(""); // Clear the input after sending
      onSend(); // Trigger the onSend callback
    }
  };

  // Function to handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(event.target.value); // Update query state
    if (inputRef.current) {
      inputRef.current.style.height = "auto"; // Reset height to auto for resizing
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set height based on content
    }
  };

  // Function to handle mode selection
  const handleModeSelect = (mode: string) => {
    onModeChange(mode); // Call the prop function to update the mode in the parent
  };

  // Function to handle key down events in the textarea
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      // Check if Enter key is pressed
      if (event.shiftKey) {
        return; // Allow shift + Enter for new line
      }
      event.preventDefault(); // Prevent default behavior
      if (query.trim()) {
        handleQuery(selectedMode); // Send the query if not empty
      }
    }
  };

  // Function to stop the ongoing query
  const handleStopQuery = () => {
    abortQuery(); // Call abort function from context
  };

  return (
    <div>
      <Form className="width-full flex-fill">
        <InputGroup className="my-3 justify-content-center position-relative">
          <InputGroup.Text>
            <i className="bi bi-search"></i> {/* Search icon */}
          </InputGroup.Text>
          <Form.Control
            as="textarea" // Use textarea for input
            ref={inputRef} // Reference to the textarea
            disabled={isLoadingQuery} // Disable input when loading
            id="inlineFormInputGroup"
            placeholder="Ask anything ..." // Placeholder text
            value={query} // Bind query state to value
            onChange={handleInputChange} // Handle input changes
            onKeyDown={handleKeyDown} // Handle key down events
            className="pe-5"
            style={{
              resize: "none", // Disable resizing
              overflow: "hidden", // Hide overflow
              maxHeight: "200px", // Set max height
              borderRadius: "5px", // Rounded corners
            }}
            rows={1} // Start with one row
          />
          {isLoadingQuery ? ( // Conditional rendering based on loading state
            <Button variant="outline-danger" onClick={handleStopQuery}>
              <i className="bi bi-stop"></i> {/* Stop icon */}
            </Button>
          ) : (
            <Button
              variant="outline-primary"
              onClick={() => handleQuery(selectedMode)} // Send query on button click
            >
              <i
                className={
                  modeOptions.find((option) => option.value === selectedMode)
                    ?.icon // Get icon for the selected mode
                }
              ></i>
            </Button>
          )}
          <Dropdown>
            <Dropdown.Toggle variant="primary">Select Mode</Dropdown.Toggle>
            <Dropdown.Menu>
              {modeOptions.map(
                (
                  option // Map through mode options
                ) => (
                  <OverlayTrigger
                    key={option.value}
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-${option.value}`}>
                        {option.tooltip}
                      </Tooltip>
                    }
                  >
                    <Dropdown.Item
                      onClick={() => handleModeSelect(option.value)} // Handle mode selection
                      active={selectedMode === option.value} // Highlight active mode
                      className={styles.dropdownItem} // Apply styles from the SCSS module
                    >
                      <i className={option.icon}></i> {/* Mode icon */}
                      <span className="ms-2">{option.label}</span>{" "}
                      {/* Mode label */}
                    </Dropdown.Item>
                  </OverlayTrigger>
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </Form>
    </div>
  );
};

export default ChatInput; // Export the ChatInput component
