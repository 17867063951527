import React from "react";
import { Carousel } from "react-bootstrap";
import { Carousel as CarouselType, ChatItem } from "../../../types/types";
import log from "../../../utils/logger";
import ChatItemRenderer from "../ChatItemRenderer";
import "./CarouselMessage.scss";
interface CarouselMessageProps {
  carousel: CarouselType;
}
const CarouselMessage: React.FC<CarouselMessageProps> = ({ carousel }) => {
  log.debug("Rendering carousel", carousel);
  return (
    <Carousel
      interval={null}
      variant="dark"
      className="overflow-hidden pb-5 px-5"
      style={{ height: "50vh" }}
    >
      {carousel.panels.map((panel) => {
        return (
          <Carousel.Item key={panel.id} className="h-100 pb-2">
            <div className="text-center">
              <h5>{panel.title}</h5>
            </div>
            <div className="h-100 overflow-y-scroll custom-sidebar">
              {panel.messages.map((message) => (
                <div key={message.id} className="mb-2">
                  <ChatItemRenderer chatItem={message as ChatItem} />
                </div>
              ))}
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
export default CarouselMessage;
