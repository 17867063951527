import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "./SignOutButton.module.scss";

export const SignOutButton = () => {
  const { instance, accounts } = useMsal();
  const [userInitials, setUserInitials] = useState<string>("");

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const account = accounts[0];
        if (account) {
          const name = account.name || "";
          const initials = name
            .split(" ")
            .map((n) => n[0])
            .join("");
          setUserInitials(initials);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            scopes: ["User.Read"],
          });
        }
      }
    };

    loadUserData();
  }, [accounts, instance]);

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as="div"
        id="dropdown-basic"
        className="d-flex align-items-center"
      >
        <div className={styles.userInitials}>{userInitials || "U"}</div>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item onClick={handleLogout}>
          <i className="bi bi-box-arrow-right me-2" /> {/* Sign-out icon */}
          Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
