import React from "react";
import { useChatContext } from "../../../contexts/ChatContext";
import { isString } from "../../../types/typeGuards";
import type {
  SourceMessage as SourceMessageType,
  Source as SourceType,
} from "../../../types/types";
import styles from "./SourceMessage.module.scss";

interface SourceProps {
  source: SourceType;
}

const Source: React.FC<SourceProps> = ({ source }) => {
  const { changeSelectedSource } = useChatContext();
  const pageNumber = isString(source.page)
    ? parseInt(source.page)
    : source.page;
  return (
    <div
      className={`d-flex  ${styles.source} gap-1`}
      onClick={() => changeSelectedSource(source)}
    >
      <div>
        <i className="bi bi-file-earmark-pdf"></i>
        {source.fileName}
      </div>
      <div>p.{pageNumber}</div>
    </div>
  );
};

interface SourceMessageProps {
  message: SourceMessageType;
}

const SourceMessage: React.FC<SourceMessageProps> = ({ message }) => {
  return (
    <div className={`d-flex flex-row ${styles.message} `}>
      <div className={`${styles.label}`}>Sources</div>
      <div className={`d-flex flex-column gap-2`}>
        <div className={`d-flex flex-row flex-wrap gap-1`}>
          {message.content.map((source, idx) => (
            <Source key={idx} source={source} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SourceMessage;
