import { InteractionType, PopupRequest } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import "bootstrap-icons/font/bootstrap-icons.css";
import React from "react";
import { loginRequest } from "../../config/authConfig";
import MarketContent from "./components/MarketContent";

const Market: React.FC = () => {
  // Define the type for authRequest
  const authRequest: PopupRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <MarketContent />
    </MsalAuthenticationTemplate>
  );
};

export default Market;
