import { useCallback, useEffect, useRef } from "react";
import { useChatContext } from "../contexts/ChatContext";
import { Source } from "../types/types";
import log from "../utils/logger";

/**
 * Custom hook to handle click events on elements with the class `source`.
 * This hook sets up event listeners on these elements within a container,
 * and uses a MutationObserver to monitor and react to changes in the container's children.
 *
 * @returns {React.RefObject<HTMLDivElement>} - A ref object to be attached to the container element
 * that contains the `source` elements.
 */
const useSourceClickHandler = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { changeSelectedSource } = useChatContext();

  /**
   * Event handler for when a source element is clicked.
   * It extracts the relevant source information from the clicked element
   * and updates the selected source in the context.
   *
   * @param {Event} e - The click event triggered by a source element.
   */
  const handleClick: EventListener = useCallback(
    (e: Event) => {
      const target = e.currentTarget as HTMLSpanElement;
      const page = target.getAttribute("data-page");
      const filePath = target.getAttribute("data-path");
      const fileName = target.getAttribute("data-source");

      // Create a Source object with the extracted attributes
      const source = { page, fileName, filePath } as Source;

      // Update the selected source in the context
      log.debug("Source clicked, changing selected source", source);
      changeSelectedSource(source);
    },
    [changeSelectedSource] // Only recreate the handler if changeSelectedSource changes
  );

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const updateListeners = () => {
        const elements = container.querySelectorAll("span.source");
        log.debug("Updating source click event listeners", {
          elementCount: elements.length,
        });

        elements.forEach((element) => {
          element.removeEventListener("click", handleClick);
          element.addEventListener("click", handleClick);
        });
      };

      // Initial setup of event listeners
      log.debug("Initial setup of source click event listeners");
      updateListeners();

      // Set up a MutationObserver to monitor changes in the container
      const observer = new MutationObserver((mutations) => {
        log.debug("DOM mutations observed, updating listeners", mutations);
        updateListeners();
      });

      observer.observe(container, { childList: true, subtree: true });
      log.debug("MutationObserver attached to container");

      // Cleanup function
      return () => {
        log.debug(
          "Cleaning up source click event listeners and MutationObserver"
        );
        observer.disconnect();
        const elements = container.querySelectorAll("span.source");
        elements.forEach((element) => {
          element.removeEventListener("click", handleClick);
        });
      };
    }
  }, [handleClick]);

  return containerRef;
};

export default useSourceClickHandler;
