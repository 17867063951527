import { useCallback, useState } from "react";
import { useChatContext } from "../contexts/ChatContext";
import { useError } from "../contexts/ErrorContext";
import { errorToString } from "../types/typeGuards";
import { Message } from "../types/types";
import log from "../utils/logger";
import useFetchWithMsal from "./useFetchWithMsal";

export const useFeedback = () => {
  const [feedbackLoading, setFeedbackLoading] = useState<boolean>(false);
  const { feedbackEndpoint, fetchMessages } = useChatContext();
  const { execute } = useFetchWithMsal();
  const { addError } = useError();

  const updateFeedback = useCallback(
    async (message: Message, feedback: boolean) => {
      log.debug(`Updating feedback for message ${JSON.stringify(message)}`);
      setFeedbackLoading(true);
      try {
        await execute("POST", feedbackEndpoint, {
          threadId: message.threadId,
          messageId: message.id,
          feedback,
        });
        fetchMessages(message.threadId);
      } catch (error) {
        const errorMessage = errorToString(error);
        log.error("Error posting feedback:", errorMessage);
        addError(`Failed to update feedback: ${errorMessage}`);
      } finally {
        setFeedbackLoading(false);
      }
    },
    [execute, feedbackEndpoint, fetchMessages, addError]
  );

  return { feedbackLoading, updateFeedback };
};
