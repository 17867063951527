import { useCallback, useEffect, useState } from "react";
import { useError } from "../contexts/ErrorContext";
import { errorToString } from "../types/typeGuards"; // Import the errorToString function
import { Message, Thread, parseMessage, parseThread } from "../types/types";
import log from "../utils/logger"; // Import the logger
import useFetchWithMsal from "./useFetchWithMsal";

export const useThread = (
  threadsEndpoint: string,
  messagesEndpoint: string
) => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null);
  const { execute } = useFetchWithMsal();
  const { addError } = useError();

  const fetchThreads = useCallback(async () => {
    log.debug("Fetching threads from endpoint:", threadsEndpoint); // Log the endpoint
    try {
      const response = await execute("POST", threadsEndpoint);
      if (response) {
        const threads = response.map(parseThread) as Thread[];
        threads.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
        setThreads(threads);
        log.debug("Threads fetched successfully:", threads); // Log success
        return threads;
      }
    } catch (error) {
      log.error("Error fetching threads:", error); // Log error
      addError(`Error fetching threads: ${errorToString(error)}`);
    }
  }, [execute, threadsEndpoint, addError]);

  useEffect(() => {
    fetchThreads();
  }, [fetchThreads]);

  const fetchMessages = useCallback(
    async (threadId: string) => {
      log.debug("Fetching messages for thread ID:", threadId); // Log the thread ID
      try {
        const response = await execute("POST", messagesEndpoint, { threadId });
        if (response) {
          setMessages(response.messages.map(parseMessage));
          log.debug("Messages fetched successfully:", response.messages); // Log success
        }
      } catch (error) {
        log.error("Error fetching messages:", error); // Log error
        addError(`Error fetching messages: ${errorToString(error)}`);
      }
    },
    [execute, messagesEndpoint, addError]
  );

  const changeThread = useCallback(
    (thread: Thread | null, fetch: boolean = true) => {
      setSelectedThread(thread);
      if (fetch) {
        if (thread !== null) {
          log.debug("Selected thread changed, fetching messages."); // Log when a thread is selected
          fetchMessages(thread.id);
        } else {
          log.debug("No thread selected, clearing messages."); // Log when no thread is selected
          setMessages([]);
        }
      }
    },
    [setSelectedThread, setMessages, fetchMessages]
  );

  return {
    fetchThreads,
    threads,
    selectedThread,
    changeThread,
    fetchMessages,
    messages,
    setThreads,
  };
};
